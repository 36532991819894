import localesEn from '@/views/Auth/i18n/localeEn';

const en = {
  en: {
    ...localesEn.en,
    SEO_TITLE: 'Log in',
    SEO_DESCRIPTION: 'Log in and learn new skills today.',
    FORM_TITLE: 'Welcome',
    FORM_DESCRIPTION: 'Start your learning with Crehana for Business.',
    INPUT_EMAIL_LABEL: 'Email',
    INPUT_EMAIL_HINT: 'Enter your email',
    INPUT_PASSWORD_LABEL: 'Password',
    INPUT_PASSWORD_HINT: 'Enter password',
    FORGOT_PASSWORD: 'Forgot your password?',
    BUTTON_SUBMIT_LABEL: 'Log in',
    FIRST_TIME_IN_CREHANA: 'Is this your first time using Crehana?',
    REGISTER: 'Register',
    LOGIN_WITH_YOUR_ORGANIZATION: 'Log in with your organization',
    LOGIN_WITH_YOUR_ORGANIZATION_DESKTOP: 'Log in with your organization',

    GLOBAL_ERROR_CREDENTIALS: 'Invalid username or password',
    RENEWED_LOGIN_FORM_TITLE: 'Continue with the processes of your company',
    RENEWED_LOGIN_WITH_OTHER_METHOD: 'Log in with:',
    RENEWED_LOGIN_PERSONAL_ACCOUNT_LABEL: 'Do you have a personal account?',
    RENEWED_LOGIN_PERSONAL_ACCOUNT_ACTION: 'Enter here.',
    RENEWED_LOGIN_OTHER_METHOD_B2B:
      'Use another corporate authentication method',
    RENEWED_LOGIN_OTHER_METHOD_B2B_TOOLTIP: 'Methods like SAML or user ID.',
    RENEWED_LOGIN_SUBMIT_BUTTON: 'Log in',
  },
};

export default en;
