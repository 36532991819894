const translations = {
  en: {
    LOGIN_WITH_FACEBOOK_SING_UP: 'Sign up with Facebook',
    LOGIN_WITH_GOOGLE_SING_UP: 'Sign up with Google',
    LOGIN_WITH_FACEBOOK_SIGN_IN: 'Login with Facebook',
    LOGIN_WITH_GOOGLE_SIGN_IN: 'Login with Google',
    LOGIN_WITH_MICROSOFT_SIGN_IN: 'Login with Microsoft',
    LOGIN_WITH_MICROSOFT_SECURE_AUTH: 'Login with SecureAuth',

    AUTH_VALIDATION_LEGALS_REQUIRED:
      'You must accept the Terms, Conditions and Policies to continue.',
    INPUT_REQUIRED: 'Required field',
    VALIDATE_MAX_PASSWORD: 'Your password must be no more than 6 characters',
    VALIDATE_MIN_PASSWORD: 'Your password must be at least 6 characters',
    VALIDATE_FORMAT_EMAIL: 'Please enter a valid email.',
    PASSWORD_DOES_NOT_MATCH: 'The password does not match',

    LEGALS_TEXT_1: 'I accept the ',
    LEGALS_TEXT_2: 'Terms, Conditions and Policies of Crehana.',

    CONTINUE_WITH: 'Continue with:',

    SENDING: 'Sending...',

    B2C_EMAIL_MESSAGE:
      '* If you are a corporate student. [Click here]({{url}})',
    B2B_EMAIL_ACTIVATE_MESSAGE:
      '* You have not activated your account. [Activate it here]({{url}})',
    B2B_EMAIL_REGISTERED_MESSAGE:
      '* Corporate account already registered. [Log in]({{url}})',
    AUTH_VALIDATION_EMAIL_IN_USE: 'The email entered is already in use',

    OVERLAY_ERROR_TITLE: 'Oops, something went wrong',
    OVERLAY_ERROR_BUTTON_TRY_AGAIN_LABEL: 'Try again',
  },
};

export default translations;
