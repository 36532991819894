import localesPt from '@/views/Auth/i18n/localePt';

const pt = {
  pt: {
    ...localesPt.pt,
    SEO_TITLE: 'Conecte-se',
    SEO_DESCRIPTION: 'Inicie a sessão para continuar aprendendo',
    FORM_TITLE: 'Bem-vindo',
    FORM_DESCRIPTION: 'Comece seu aprendizado com Crehana para empresas.',
    INPUT_EMAIL_LABEL: 'E-mail',
    INPUT_EMAIL_HINT: 'Digite seu e-mail',
    INPUT_PASSWORD_LABEL: 'Senha',
    INPUT_PASSWORD_HINT: 'Digite seu senha',
    FORGOT_PASSWORD: 'Esqueceu sua senha?',
    BUTTON_SUBMIT_LABEL: 'Entrar',
    FIRST_TIME_IN_CREHANA: 'É a primeira vez que usa Crehana?',
    REGISTER: 'Cadastrar-se',
    LOGIN_WITH_YOUR_ORGANIZATION: 'Comece com sua organização',
    LOGIN_WITH_YOUR_ORGANIZATION_DESKTOP: 'Faça login com sua organização',

    GLOBAL_ERROR_CREDENTIALS: 'Credenciais inválidas',
    RENEWED_LOGIN_FORM_TITLE: 'Continue com os processos da sua empresa',
    RENEWED_LOGIN_WITH_OTHER_METHOD: 'Inicie com:',
    RENEWED_LOGIN_PERSONAL_ACCOUNT_LABEL: 'Tem uma conta pessoal?',
    RENEWED_LOGIN_PERSONAL_ACCOUNT_ACTION: 'Entre aqui.',
    RENEWED_LOGIN_OTHER_METHOD_B2B:
      'Usa outro método de autenticação corporativa',
    RENEWED_LOGIN_OTHER_METHOD_B2B_TOOLTIP:
      'Métodos como SAML ou ID de usuário',
    RENEWED_LOGIN_SUBMIT_BUTTON: 'Entrar',
  },
};

export default pt;
