import { FC } from 'react';

import { CustomNextImage as Image } from '@crehana/next';

const IMAGE_URL_B2B =
  'https://crehana-vercel-images-prd.imgix.net/public/auth-mf/7c8e92d1-2314-42c4-949e-5940efc5ad91-5-1.png?auto=format&fit=clip';

export const LoginLayout: FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="tw-relative tw-text-white tw-w-full tw-h-full tw-bg-neutral-light-100 tw-flex tw-items-center tw-justify-center tw-mx-auto tw-gap-7.2">
      <div className="tw-hidden md:tw-block">
        <Image
          alt="Crehana"
          width={529}
          height={580}
          className="tw-w-full"
          src={IMAGE_URL_B2B}
        />
      </div>

      <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-w-full tw-min-h-screen tw-py-6.4 tw-max-w-[55rem] tw-px-2 sm:tw-px-0">
        <div className="tw-relative tw-overflow-x-auto">{children}</div>
      </div>
    </div>
  );
};
