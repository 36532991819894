import localesEs from '@/views/Auth/i18n/localeEs';

const es = {
  es: {
    ...localesEs.es,
    SEO_TITLE: 'Inicia sesión',
    SEO_DESCRIPTION: 'Inicia sesión y aprende nuevas habilidades hoy.',
    FORM_TITLE: 'Bienvenido',
    FORM_DESCRIPTION: 'Comienza tu aprendizaje con Crehana para Empresas.',
    INPUT_EMAIL_LABEL: 'Correo',
    INPUT_EMAIL_HINT: 'Ingresa tu correo',
    INPUT_PASSWORD_LABEL: 'Contraseña',
    INPUT_PASSWORD_HINT: 'Ingresa contraseña',
    FORGOT_PASSWORD: '¿Olvidaste la contraseña?',
    BUTTON_SUBMIT_LABEL: 'Inicia sesión',
    FIRST_TIME_IN_CREHANA: '¿Es la primera vez que usas Crehana?',
    REGISTER: 'Regístrate',
    LOGIN_WITH_YOUR_ORGANIZATION: 'Inicia con tu organización',
    LOGIN_WITH_YOUR_ORGANIZATION_DESKTOP: 'Inicia sesión con tu organización',

    GLOBAL_ERROR_CREDENTIALS: 'Usuario y/o contraseña incorrectos',
    RENEWED_LOGIN_FORM_TITLE: 'Continúa con los procesos de tu compañía',
    RENEWED_LOGIN_WITH_OTHER_METHOD: 'Inicia sesión con:',
    RENEWED_LOGIN_PERSONAL_ACCOUNT_LABEL:
      '¿Tienes una cuenta a título personal?',
    RENEWED_LOGIN_PERSONAL_ACCOUNT_ACTION: 'Ingresa aquí.',
    RENEWED_LOGIN_OTHER_METHOD_B2B:
      'Usa otro método de autenticación corporativo',
    RENEWED_LOGIN_OTHER_METHOD_B2B_TOOLTIP: 'Métodos como SAML o ID de usuario',
    RENEWED_LOGIN_SUBMIT_BUTTON: 'Iniciar sesión',
  },
};

export default es;
