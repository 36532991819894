import localeEn from './localeEn';
import localeEs from './localeEs';
import localePt from './localePt';

const translations = {
  es: localeEs.es,
  pt: localePt.pt,
  en: localeEn.en,
};

export default translations;
