import { useForm } from 'react-hook-form';

import { useMutation } from '@apollo/client';
import { get } from 'lodash';

import QuestionMarkCircleIcon from '@crehana/compass.icons.outline/QuestionMarkCircle';
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@crehana/compass.ui/v2';
import { useTranslation } from '@crehana/i18n';
import { useIsClient } from '@crehana/react-hooks';
import { TextField } from '@crehana/ui';

import { Divider, EmailInput } from '@/views/Auth/components';
import { validations } from '@/views/Auth/constants';
import AUTH_PAGES_LOGIN_USER_MUTATION from '@/views/Auth/graphql/AuthPagesLoginUserMutation.auth.graphql';
import {
  AuthPagesLoginUserMutation,
  AuthPagesLoginUserMutationVariables,
} from '@/views/Auth/graphql/types/AuthPagesLoginUserMutation';
import routes from '@/views/Auth/routes';
import {
  getEmailParam,
  getNextUrl,
  redirectWithQueryParams,
} from '@/views/Auth/utils';

import { B2BSocialButtons } from './b2b-social-buttons/b2b-social-buttons';

type TFormValues = {
  email: string;
  password: string;
};
export const LoginForm = () => {
  const { t } = useTranslation();
  const isClient = useIsClient();
  const nextUrl = getNextUrl();
  const defaultEmail = getEmailParam() as string;
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<TFormValues>({
    defaultValues: {
      email: defaultEmail,
      password: '',
    },
  });

  const [loginUser] = useMutation<
    AuthPagesLoginUserMutation,
    AuthPagesLoginUserMutationVariables
  >(AUTH_PAGES_LOGIN_USER_MUTATION, {
    context: { clientName: 'auth' },
    onCompleted: data => {
      if (data?.loginUser?.success) {
        window.location.replace(data?.loginUser?.redirect || '');
      } else {
        setError('email', {
          type: 'manual',
          message: get(data?.loginUser?.messages, '[0].message'),
        });
      }
    },
  });

  const onSubmit = (data: TFormValues) =>
    loginUser({
      variables: {
        username: data.email,
        password: data.password,
        nextUrl,
      },
    });

  const toggleViewToPersonalAccount = () => {
    redirectWithQueryParams('/login/student/');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="tw-w-full">
      <div className="tw-text-left tw-mb-1.6">
        <h3 className="tw-font-h3">{t('RENEWED_LOGIN_FORM_TITLE')}</h3>
      </div>
      {isClient && <B2BSocialButtons />}
      <Divider className="tw-pb-2.4 tw-font-body4 tw-text-base-main" />
      <EmailInput
        className="mb-24"
        id="email"
        name="email"
        label={t('INPUT_EMAIL_LABEL')}
        hint={t('INPUT_EMAIL_HINT')}
        ref={register({
          required: validations.required(t),
          pattern: validations.email(t),
        })}
        defaultValue={defaultEmail}
        disabled={isSubmitting}
        errorText={errors.email?.message}
        full
        hideB2CMsg
        hideB2BLoginMsg
      />
      <TextField
        className="mb-8"
        id="password"
        name="password"
        type="password"
        label={t('INPUT_PASSWORD_LABEL')}
        hint={t('INPUT_PASSWORD_HINT')}
        errorText={errors.password?.message}
        ref={register({
          required: validations.required(t),
          minLength: validations.minLength(t),
          maxLength: validations.maxLength(t),
        })}
        disabled={isSubmitting}
        full
      />
      <a
        href={routes.b2bRecoverPasswordPath}
        className="tw-block tw-font-caption tw-text-neutral-light-700 tw-mb-3.2"
      >
        {t('FORGOT_PASSWORD')}
      </a>
      <Button
        size="md"
        type="submit"
        variant="primary"
        disabled={isSubmitting}
        className="tw-mx-auto tw-block tw-mb-3.2"
      >
        {t('RENEWED_LOGIN_SUBMIT_BUTTON')}
      </Button>

      <div className="tw-flex tw-flex-col tw-gap-3.2 tw-items-center">
        <p className="tw-text-link-s tw-gap-0.4 tw-max-w-full tw-whitespace-normal tw-hidden">
          {t('RENEWED_LOGIN_OTHER_METHOD_B2B')}
          <TooltipProvider>
            <Tooltip delayDuration={500}>
              <TooltipTrigger
                className="tw-text-neutral-light-800 dark:tw-text-neutral-light-100"
                asChild
              >
                <span>
                  <QuestionMarkCircleIcon
                    size={20}
                    className="tw-text-primary-light-500 tw-cursor-pointer tw-mt-0.4"
                  />
                </span>
              </TooltipTrigger>
              <TooltipContent align="center" side="top">
                <p>{t('RENEWED_LOGIN_OTHER_METHOD_B2B_TOOLTIP')}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </p>
        <div className="tw-flex tw-flex-col xs:tw-flex-row tw-items-center tw-justify-center tw-gap-0.4">
          <span className="tw-font-body5 tw-text-neutral-light-700">
            {t('RENEWED_LOGIN_PERSONAL_ACCOUNT_LABEL')}
          </span>
          <span
            tabIndex={0}
            role="button"
            onClick={toggleViewToPersonalAccount}
            className="tw-font-caption tw-text-neutral-light-700 hover:tw-cursor-pointer hover:tw-underline"
            onKeyDown={e => {
              if (e.key === 'Enter') {
                toggleViewToPersonalAccount();
              }
            }}
          >
            {t('RENEWED_LOGIN_PERSONAL_ACCOUNT_ACTION')}
          </span>
        </div>
      </div>
    </form>
  );
};
