import { FC } from 'react';

import { useTranslation } from '@crehana/i18n';

import { SocialButtonsMinimal } from './social-buttons-minimal';

type TB2BSocialButtonsProps = {
  nextUrl?: string;
};

export const B2BSocialButtons: FC<TB2BSocialButtonsProps> = ({ nextUrl }) => {
  const { t } = useTranslation();

  return (
    <div className="tw-flex tw-flex-col tw-items-start sm:tw-items-center sm:tw-flex-row tw-mb-2.4">
      <span className="tw-font-subtitle4 tw-mr-2.4">
        {t('RENEWED_LOGIN_WITH_OTHER_METHOD')}
      </span>
      <div>
        <SocialButtonsMinimal
          authType="signIn"
          hideFacebookLink
          hideFacebookButton
          nextUrl={nextUrl}
          showMicrosoftButton
        />
      </div>
    </div>
  );
};
