import { withAuth, withSeo } from '@/views/Auth/hocs';
import { getServerSideI18nProps } from '@/views/Auth/utils';

import { LoginForm } from './components/login-form';
import { LoginLayout } from './components/login-layout';
import translations from './i18n';

const B2BLoginV2 = () => (
  <LoginLayout>
    <LoginForm />
  </LoginLayout>
);

export const getServerSideProps = getServerSideI18nProps({
  pageKey: 'b2b-login',
  translations,
});

export default withSeo(withAuth(B2BLoginV2, { authenticated: false }));
