import { FC, useEffect } from 'react';

import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { MeObject } from '@crehana/auth';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@crehana/compass.ui/tooltip';
import { useTranslation } from '@crehana/i18n';
import FacebookLogo from '@crehana/icons/FacebookLogo';
import GoogleIcon from '@crehana/icons/GoogleIcon';

import MicrosoftButton from '@/shared/components/AuthForm/components/MicrosoftButton';
import { useSocialAuth } from '@/shared/components/AuthForm/components/SocialButtons/react-hooks';
import { AuthFormSocialloginMutation } from '@/shared/components/AuthForm/graphql/types/AuthFormSocialloginMutation';
import { getNextUrl } from '@/shared/components/AuthForm/utils';

import { LoadingOverlay } from './loading-overlay';

type TSocialButtonsMinimal = {
  shouldRenderFacebookButton?: boolean;
  referFriendId?: string;
  nextUrl?: string;
  customHandler?(params: {
    redirect?: string | null;
    user?: MeObject;
    isNewUser?: boolean | null;
    graphqlResponse?: AuthFormSocialloginMutation['socialLogin'];
  }): void;
  currentForm?: string;
  hideFacebookButton?: boolean;
  hideFacebookLink?: boolean;
  showMicrosoftButton?: boolean;
  friendInvitationId?: string;
  authType?: 'signUp' | 'signIn';
};

const configuration: Configuration = {
  auth: {
    clientId: '2660be26-2a13-4cec-82c3-337ab10852b0',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: '/home/',
  },
};
const pca = new PublicClientApplication(configuration);

export const SocialButtonsMinimal: FC<
  React.PropsWithChildren<TSocialButtonsMinimal>
> = ({
  referFriendId,
  nextUrl: _nextUrl,
  customHandler,
  hideFacebookButton,
  shouldRenderFacebookButton = false,
  hideFacebookLink,
  showMicrosoftButton,
  friendInvitationId,
  authType,
}) => {
  const { t } = useTranslation();

  const {
    gmailButtonProps,
    facebookButtonProps,
    setupAndInitSocialAuthScripts,
    authState: { processingSocialAuth, loadingFacebookAndGoogleScripts },
  } = useSocialAuth({
    nextUrl: friendInvitationId ? '/premium/' : _nextUrl,
    referFriendId,
    friendInvitationId,
    onSuccess: customHandler,
    hideFacebookLink,
    getNextUrl: friendInvitationId ? undefined : getNextUrl,
  });

  useEffect(() => {
    setupAndInitSocialAuthScripts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const facebookLabel =
    authType === 'signUp'
      ? t('LOGIN_WITH_FACEBOOK_SING_UP', 'Regístrate con Facebook')
      : t('LOGIN_WITH_FACEBOOK_SIGN_IN', 'Ingresar con Facebook');
  const googleLabel =
    authType === 'signUp'
      ? t('LOGIN_WITH_GOOGLE_SING_UP', 'Regístrate con Google')
      : t('LOGIN_WITH_GOOGLE_SIGN_IN', 'Ingresar con Google');

  const renderFBAuth = () => {
    if (hideFacebookButton && hideFacebookLink) return null;
    if (hideFacebookButton && !hideFacebookLink) {
      return (
        <TooltipProvider>
          <Tooltip delayDuration={500}>
            <TooltipTrigger
              className="tw-text-neutral-light-800 dark:tw-text-neutral-light-100"
              asChild
            >
              <a
                className="tw-flex tw-items-center tw-justify-center tw-w-4.8 tw-h-4.8 tw-bg-white tw-rounded-full"
                href={`https://www.crehana.com/login/facebook/?next=${_nextUrl}`}
                {...facebookButtonProps}
              >
                <FacebookLogo size={28} className="tw-text-neutral-light-100" />
              </a>
            </TooltipTrigger>
            <TooltipContent align="center" side="bottom">
              <p>{facebookLabel}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }
    return (
      <TooltipProvider>
        <Tooltip delayDuration={500}>
          <TooltipTrigger
            className="tw-text-neutral-light-800 dark:tw-text-neutral-light-100"
            asChild
          >
            <button
              className="tw-flex tw-items-center tw-justify-center tw-w-4.8 tw-h-4.8 tw-bg-white tw-rounded-full"
              type="button"
              {...facebookButtonProps}
            >
              <FacebookLogo size={28} />
            </button>
          </TooltipTrigger>
          <TooltipContent align="center" side="bottom">
            <p>{facebookLabel}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  return (
    <div className="w-full flex">
      <div className="flex items-center justify-center m-auto max-w-280">
        {shouldRenderFacebookButton && renderFBAuth()}

        <TooltipProvider>
          <Tooltip delayDuration={500}>
            <TooltipTrigger
              className="tw-text-neutral-light-800 dark:tw-text-neutral-light-100"
              asChild
            >
              <button
                className="tw-flex tw-items-center tw-justify-center tw-w-4.8 tw-h-4.8 tw-mr-0.8 tw-bg-white tw-rounded-full"
                type="button"
                style={{
                  display: window.navigator.userAgent
                    .toLowerCase()
                    .includes('instagram')
                    ? 'none !important'
                    : 'flex',
                }}
                {...gmailButtonProps}
              >
                <GoogleIcon size={24} className="tw-text-neutral-light-100" />
              </button>
            </TooltipTrigger>
            <TooltipContent align="center" side="bottom">
              <p>{googleLabel}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      {showMicrosoftButton && (
        <MsalProvider instance={pca}>
          <div>
            <MicrosoftButton v4 />
          </div>
        </MsalProvider>
      )}
      {(processingSocialAuth || loadingFacebookAndGoogleScripts) && (
        <LoadingOverlay />
      )}
    </div>
  );
};
